"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _errorParse = require("@/utils/error-parse");
var _elementUi = require("element-ui");
var _warehouseRecord = require("@/api/warehouseRecord");
var _warehouse = require("@/api/warehouse");
var _warehouseArea = require("@/api/warehouseArea");
var _rawMaterial = require("@/api/rawMaterial");
var _warehouseAreaRawMaterial = require("@/api/warehouseAreaRawMaterial");
var _i18n = _interopRequireDefault(require("@/i18n"));
var _vueSelect = _interopRequireDefault(require("vue-select"));
require("vue-select/src/scss/vue-select.scss");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    vSelect: _vueSelect.default
  },
  data() {
    var qtyValidator = (rule, value, callback) => {
      const valueNum = parseFloat(value);
      if (this.action !== 'stock_take' && (value === '' || value === null || value === undefined || value === 0 || value === '0' || valueNum <= 0 || isNaN(valueNum))) {
        callback(new Error('Please Add qty'));
      } else {
        // if (this.ruleForm.checkPass !== '') {
        //   this.$refs.ruleForm.validateField('checkPass');
        // }
        callback();
      }
    };
    return {
      loading: false,
      warehouseRecord: {
        id: null,
        name: null,
        warehouseId: null,
        warehouseAreaId: null,
        rawMaterialId: null,
        toWarehouseId: null,
        toWarehouseAreaId: null,
        action: null,
        qty: 0,
        mainqty: 0,
        subqty: 0,
        remark: null
      },
      actions: [{
        name: 'stock_in',
        description: 'Stock In'
      }, {
        name: 'stock_transfer',
        description: 'Stock Transfer'
      }, {
        name: 'stock_out',
        description: 'Stock Out'
      }],
      warehouses: [],
      warehouseAreas: [],
      warehouseAreaRawMaterials: [],
      toWarehouseAreas: [],
      disableWarehouse: false,
      disableWarehouseArea: false,
      disableRawMaterial: false,
      rawMaterials: [],
      rules: {
        qty: [{
          validator: qtyValidator,
          trigger: 'blur'
        }]
      },
      buttonText: null
    };
  },
  computed: {
    device() {
      return this.$store.state.app.device;
    },
    warehouse: {
      get() {
        if (this.warehouseRecord.warehouseId) {
          return this.warehouses.find(warehouse => warehouse.uuid === this.warehouseRecord.warehouseId);
        }
        return null;
      }
    },
    toWarehouse: {
      get() {
        if (this.warehouseRecord.toWarehouseId) {
          return this.warehouses.find(warehouse => warehouse.uuid === this.warehouseRecord.toWarehouseId);
        }
        return null;
      }
    },
    warehouseArea: {
      get() {
        if (this.warehouseRecord.warehouseAreaId) {
          return this.warehouseAreas.find(warehouseArea => warehouseArea.uuid === this.warehouseRecord.warehouseAreaId);
        }
        return null;
      }
    },
    rawMaterialUnit: {
      get() {
        if (this.warehouseRecord.rawMaterialId) {
          const rawMaterial = this.rawMaterials.find(rawMaterial => rawMaterial.uuid === this.warehouseRecord.rawMaterialId);
          return rawMaterial ? rawMaterial.unit : null;
        } else {
          return null;
        }
      }
    },
    rawMaterialSubUnit: {
      get() {
        if (this.warehouseRecord.rawMaterialId) {
          const rawMaterial = this.rawMaterials.find(rawMaterial => rawMaterial.uuid === this.warehouseRecord.rawMaterialId);
          return rawMaterial ? rawMaterial.subUnit : null;
        } else {
          return null;
        }
      }
    },
    rawMaterialSubUnitQty: {
      get() {
        if (this.warehouseRecord.rawMaterialId) {
          const rawMaterial = this.rawMaterials.find(rawMaterial => rawMaterial.uuid === this.warehouseRecord.rawMaterialId);
          return rawMaterial ? rawMaterial.subUnitQty : null;
        } else {
          return null;
        }
      }
    }
  },
  watch: {
    warehouse() {
      if (!this.disableWarehouse) {
        this.filterRawMaterial();
      }
    },
    warehouseArea() {
      if (!this.disableWarehouseArea) {
        this.filterRawMaterial();
      }
    } /* ,
      toWarehouse() {
       this.filterToWarehouseArea()
      } */
  },

  async created() {
    if (this.$route.params.id) {
      this.warehouseRecord.warehouseId = this.$route.params.id;
      this.disableWarehouse = true;
    }
    if (this.$route.params.area_id) {
      this.warehouseRecord.warehouseAreaId = this.$route.params.area_id;
      this.disableWarehouseArea = true;
    }
    if (this.$route.query.rawMaterialId) {
      this.warehouseRecord.rawMaterialId = this.$route.query.rawMaterialId;
      this.disableRawMaterial = true;
    }
    (0, _warehouseAreaRawMaterial.getWarehouseAreaRawMaterials)({
      warehouseAreaId: this.$route.params.area_id,
      rawMaterialId: this.$route.query.rawMaterialId,
      limit: 1
    }).then(warehouseAreaRawMaterials => {
      this.warehouseAreaRawMaterials = warehouseAreaRawMaterials.data;
    });
    this.filterRawMaterial().then(() => {
      if (this.$route.query.qty) {
        this.warehouseRecord.qty = this.$route.query.qty;
        this.warehouseRecord.mainqty = Math.floor(this.$route.query.qty);
        if (this.warehouseRecord.rawMaterialId) {
          var rawMaterial = this.rawMaterials.find(rawMaterial => rawMaterial.uuid === this.warehouseRecord.rawMaterialId);
          if (rawMaterial) {
            this.warehouseRecord.subqty = (this.warehouseRecord.qty - this.warehouseRecord.mainqty) * rawMaterial.subUnitQty;
          }
        }
        this.stocktakeOriginalQty = this.$route.query.qty;
      }
    });
    /* if (this.disableWarehouse) {
      getWarehouse(this.warehouseRecord.warehouseId).then(warehouse => {
        this.warehouses = [warehouse.data]
      })
    } else { */
    if (this.$route.query.rawMaterialId) {
      (0, _warehouse.getWarehouses)({
        limit: 100,
        rawMaterialId: this.$route.query.rawMaterialId
      }).then(warehouses => {
        this.warehouses = warehouses.data;
      });
    } else {
      (0, _warehouse.getWarehouses)({
        limit: 100
      }).then(warehouses => {
        this.warehouses = warehouses.data;
      });
    }
    // }

    if (this.disableWarehouseArea) {
      (0, _warehouseArea.getWarehouseArea)(this.warehouseRecord.warehouseAreaId).then(warehouseArea => {
        this.warehouseAreas = [warehouseArea.data];
      });
    } else {
      (0, _warehouseArea.getWarehouseAreas)({
        limit: 9999,
        warehouseId: this.$route.params.id
      }).then(warehouseAreas => {
        this.warehouseAreas = warehouseAreas.data;
        this.toWarehouseAreas = warehouseAreas.data;
      });
    }
    this.buttonText = _i18n.default.t('WarehouseRecord.Add');
    if (this.$route.params.pathMatch === 'stock-in') {
      this.warehouseRecord.action = 'stock_in';
    } else if (this.$route.params.pathMatch === 'stock-out') {
      this.warehouseRecord.action = 'stock_out';
    } else if (this.$route.params.pathMatch === 'stock-take') {
      this.warehouseRecord.action = 'stock_take';
    } else if (this.$route.params.pathMatch === 'stock-transit') {
      this.warehouseRecord.action = 'stock_transfer';
    }
  },
  methods: {
    /* async filterToWarehouseArea() {
      const filter = {
        limit: 9999
      }
      if (this.warehouseRecord.toWarehouseId) {
        filter.warehouseId = this.warehouseRecord.toWarehouseId
      }
      const warehouseAreas = await getWarehouseAreas(filter)
      this.toWarehouseAreas = warehouseAreas.data
    }, */
    async onTargetWarehouseSelected() {
      (0, _warehouseArea.getWarehouseAreas)({
        limit: 9999,
        warehouseId: this.warehouseRecord.toWarehouseId
      }).then(warehouseAreasResponse => {
        let warehouseAreas = warehouseAreasResponse.data;
        if (this.$route.params.id === this.warehouseRecord.toWarehouseId) {
          warehouseAreas = warehouseAreasResponse.data.filter(warehouseArea => warehouseArea.uuid !== this.warehouseRecord.warehouseAreaId);
          // console.log('in', warehouseAreas)
        } /* else {
          console.log('out', this.$route.params.id, this.warehouseRecord.toWarehouseId)
          } */

        this.toWarehouseAreas = warehouseAreas;
      });
    },
    async filterRawMaterial() {
      if (this.warehouseRecord.rawMaterialId) {
        return (0, _rawMaterial.getRawMaterial)(this.warehouseRecord.rawMaterialId).then(rawMaterial => {
          this.rawMaterials = [rawMaterial.data];
        }).catch(error => {
          if (error.status === 404) {
            _elementUi.MessageBox.alert('Raw Material not exist, please choose your raw material', {
              confirmButtonText: 'Ok'
            }).then(() => {
              let url = '/warehouses';
              if (this.$route.params.id) {
                url += `/${this.$route.params.id}`;
                if (this.$route.params.area_id) {
                  url += `/areas/${this.$route.params.area_id}/raw-materials`;
                }
              }
              this.$router.push(url);
              return Promise.resolve('redirected');
            }).catch(err => err === 'cancel' ? Promise.resolve('cancelled') : Promise.reject(err));
          } else {
            return Promise.reject(error);
          }
        });
      }
      const filter = {
        listAll: true,
        limit: 100
      };
      if (this.warehouseRecord.warehouseId) {
        filter.warehouseId = this.warehouseRecord.warehouseId;
      }
      if (this.warehouseRecord.warehouseAreaId) {
        filter.warehouseAreaId = this.warehouseRecord.warehouseAreaId;
      }
      return (0, _rawMaterial.getRawMaterials)(filter).then(rawMaterials => {
        this.rawMaterials = rawMaterials.data;
      });
    },
    calculatedQty() {
      if (this.warehouseRecord.rawMaterialId) {
        var rawMaterial = this.rawMaterials.find(rawMaterial => rawMaterial.uuid === this.warehouseRecord.rawMaterialId);
        if (rawMaterial) {
          this.warehouseRecord.qty = this.warehouseRecord.mainqty + this.warehouseRecord.subqty / rawMaterial.subUnitQty;
        }
      }
    },
    calculatedMainAndSubQty() {
      if (this.warehouseRecord.rawMaterialId) {
        var rawMaterial = this.rawMaterials.find(rawMaterial => rawMaterial.uuid === this.warehouseRecord.rawMaterialId);
        if (rawMaterial) {
          this.warehouseRecord.mainqty = Math.floor(this.warehouseRecord.qty);
          this.warehouseRecord.subqty = (this.warehouseRecord.qty - this.warehouseRecord.mainqty) * rawMaterial.subUnitQty;
        }
      }
    },
    onSubmit() {
      this.$refs.form.validate(valid => {
        if (!valid) {
          return false;
        }
        this.loading = true;
        if (this.warehouseRecord.qty === null) {
          this.warehouseRecord.qty = 0;
        }
        (0, _warehouseRecord.createWarehouseRecord)(this.warehouseRecord).then(response => {
          // console.log(response.data.warehouse)
          (0, _elementUi.Message)({
            message: _i18n.default.t('WarehouseRecord.WarehouseRecordcreated'),
            type: 'success',
            duration: 5 * 1000
          });
          this.$router.go(-1);
        }).catch(err => {
          (0, _elementUi.Message)({
            dangerouslyUseHTMLString: true,
            message: (0, _errorParse.parseError)(err) || _i18n.default.t('WarehouseRecord.UpdateFailedMessage'),
            type: 'error',
            duration: 5 * 1000
          });
          this.loading = false;
        });
      });
    },
    onCancel() {
      this.$router.go(-1);
    }
  }
};
exports.default = _default;