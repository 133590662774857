"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _errorParse = require("@/utils/error-parse");
var _elementUi = require("element-ui");
var _warehouseAreaRawMaterial = require("@/api/warehouseAreaRawMaterial");
var _warehouseArea = require("@/api/warehouseArea");
var _rawMaterial = require("@/api/rawMaterial");
var _i18n = _interopRequireDefault(require("@/i18n"));
var _vueSelect = _interopRequireDefault(require("vue-select"));
require("vue-select/src/scss/vue-select.scss");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    vSelect: _vueSelect.default
    // Pagination
  },

  data() {
    return {
      loading: false,
      warehouseAreaRawMaterial: {
        id: '',
        name: null,
        warehouseAreaId: null,
        rawMaterialId: null
      },
      rawMaterialOptions: {
        warehouseId: null,
        nonExistInWarehouseArea: null,
        limit: 80,
        page: 1,
        listAll: true,
        keyword: null
      },
      warehouseAreas: [],
      rawMaterials: [],
      rawMaterialToShow: 0,
      buttonText: null
    };
  },
  computed: {
    /* filtered() {
      return this.warehouseAreas
    },
    paginated() {
      return this.warehouseAreas.slice(0, this.limit)
    }, */
    hasNextPage() {
      return this.rawMaterials.length < this.rawMaterialToShow;
    }
  },
  async created() {
    if (this.$route.params.area_id) {
      this.warehouseAreaRawMaterial.warehouseAreaId = this.$route.params.area_id;
      const warehouseArea = await (0, _warehouseArea.getWarehouseArea)(this.warehouseAreaRawMaterial.warehouseAreaId);
      this.warehouseAreas = [warehouseArea.data];
      this.buttonText = _i18n.default.t('WarehouseAreaRawMaterial.Add');
      this.rawMaterialOptions.nonExistInWarehouseArea = this.$route.params.area_id;
      // this.rawMaterialOptions.warehouseAreaId = this.warehouseAreas.find(area => area.uuid === this.warehouseAreaRawMaterial.warehouseAreaId).warehouse.uuid

      // warehouseArea = this.warehouseAreas.find(area => area.uuid === this.warehouseAreaRawMaterial.warehouseAreaId)
    } else if (this.$route.params.id) {
      // @TODO never go here, please cleanup
      const warehouseAreas = await (0, _warehouseArea.getWarehouseAreas)({
        warehouseId: this.$route.params.id
      });
      const warehouseArea = warehouseAreas.data[0];
      // this.warehouseAreaRawMaterial.warehouseAreaId = this.$route.params.id => this id is warehouseUUID
      this.rawMaterialOptions.nonExistInWarehouseArea = this.$route.params.id;
      this.warehouseAreas = [warehouseArea];
      this.buttonText = _i18n.default.t('WarehouseAreaRawMaterial.Edit');
      this.fetchData();
    }
    // required this.warehouseAreas
    this.fetchRawMaterial();
  },
  mounted() {
    /**
     * You could do this directly in data(), but since these docs
     * are server side rendered, IntersectionObserver doesn't exist
     * in that environment, so we need to do it in mounted() instead.
     */
    this.observer = new IntersectionObserver(this.infiniteScroll);
  },
  methods: {
    async fetchRawMaterial(keyword = '', loading = null) {
      if (loading) {
        loading(true);
        this.observer.disconnect();
        this.rawMaterialOptions = {
          ...this.rawMaterialOptions,
          page: 1,
          keyword
        };
      } else {
        this.rawMaterialOptions = {
          ...this.rawMaterialOptions,
          keyword
        };
      }
      return (0, _rawMaterial.getRawMaterials)(this.rawMaterialOptions).then(response => {
        this.rawMaterialToShow = response.meta.total;
        if (loading) {
          this.rawMaterials = Object.freeze(response.data.map(rawMaterial => {
            return {
              uuid: rawMaterial.uuid,
              sku: rawMaterial.sku,
              id: rawMaterial.uuid
            };
          }));
          this.onOpen();
        } else {
          this.rawMaterials = this.rawMaterials.concat(response.data.map(rawMaterial => {
            return {
              uuid: rawMaterial.uuid,
              sku: rawMaterial.sku,
              id: rawMaterial.uuid
            };
          }));
        }
      }).catch(error => console.error(error)).finally(() => {
        if (loading) {
          loading(false);
        }
      });
    },
    async fetchData() {
      this.loading = true;
      (0, _warehouseAreaRawMaterial.getWarehouseAreaRawMaterial)(this.$route.params.id ? this.$route.params.id : this.$route.params.warehouseAreaId).then(response => {
        this.warehouseAreaRawMaterial = response.data;
      }).catch(error => console.error(error)).finally(() => {
        this.loading = false;
      });
    },
    async onOpen() {
      if (this.hasNextPage) {
        await this.$nextTick();
        if (this.$refs.load !== undefined) {
          this.observer.observe(this.$refs.load);
        }
      }
    },
    onClose() {
      this.observer.disconnect();
    },
    async infiniteScroll([{
      isIntersecting,
      target
    }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.rawMaterialOptions.page++;
        await this.fetchRawMaterial();
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },
    onSubmit() {
      this.$refs.form.validate(valid => {
        if (!valid) {
          return false;
        }
        this.loading = true;
        return (0, _warehouseAreaRawMaterial.createWarehouseAreaRawMaterial)(this.warehouseAreaRawMaterial).then(response => {
          (0, _elementUi.Message)({
            message: _i18n.default.t('WarehouseAreaRawMaterial.WarehouseAreaRawMaterialcreated'),
            type: 'success',
            duration: 5 * 1000
          });
          this.$router.push(`/warehouses/${this.$route.params.id}/areas/` + response.data.area.uuid + '/raw-materials');
        }).catch(err => {
          (0, _elementUi.Message)({
            dangerouslyUseHTMLString: true,
            message: (0, _errorParse.parseError)(err) || _i18n.default.t('WarehouseAreaRawMaterial.UpdateFailedMessage'),
            type: 'error',
            duration: 5 * 1000
          });
          this.loading = false;
        });
      });
    },
    onCancel() {
      this.$router.go(-1);
    }
  }
};
exports.default = _default;