var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "action-container" },
        [
          _c(
            "el-row",
            {
              staticClass: "row-bg",
              attrs: { justify: "space-between", gutter: 10 },
            },
            [
              _c(
                "el-col",
                {
                  staticStyle: { "margin-bottom": "10px" },
                  attrs: { span: 24, md: 12, lg: 12 },
                },
                [
                  _c(
                    "el-button",
                    { attrs: { type: "default" }, on: { click: _vm.back } },
                    [_vm._v(_vm._s(_vm.$t("Warehouse.Back")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "router-link",
                    {
                      staticClass: "el-button el-button--success is-plain",
                      attrs: {
                        to:
                          "/warehouses/" +
                          _vm.$route.params.id +
                          "/areas/" +
                          _vm.$route.params.area_id +
                          "/add-raw-material",
                      },
                    },
                    [
                      _c("i", { staticClass: "el-icon-circle-plus" }),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("Warehouse.AddWarehouseAreaRawMaterial")
                          )
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 24, md: 12, lg: 12 } },
                [
                  _c(
                    "el-form",
                    {
                      attrs: { inline: true },
                      nativeOn: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.fetchData($event)
                        },
                      },
                    },
                    [
                      _c(
                        "el-row",
                        {
                          attrs: {
                            type: "flex",
                            justify: _vm.device === "mobile" ? "start" : "end",
                            gutter: 10,
                          },
                        },
                        [
                          _c(
                            "span",
                            { staticStyle: { "padding-left": "5px" } },
                            [
                              _c(
                                "el-form-item",
                                { staticStyle: { "margin-bottom": "10px" } },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.listQuery.keyword,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.listQuery, "keyword", $$v)
                                      },
                                      expression: "listQuery.keyword",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { staticStyle: { "margin-bottom": "10px" } },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "primary",
                                        icon: "el-icon-search",
                                      },
                                      on: { click: _vm.fetchData },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("Order.Search")))]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: {
            data: _vm.list,
            "element-loading-text": "Loading",
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "ID", width: "95" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v("\n        " + _vm._s(scope.row.id) + "\n      "),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("Warehouse.SKU") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.rawMaterial.sku) +
                        "\n        " +
                        _vm._s(
                          scope.row.rawMaterial.nameEng !==
                            scope.row.rawMaterial.sku
                            ? scope.row.rawMaterial.nameEng
                            : null
                        ) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("Warehouse.QTY") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.qty) +
                        "\n        " +
                        _vm._s(
                          _vm.pluralize(
                            scope.row.rawMaterial.unit,
                            scope.row.qty
                          )
                        ) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Packing", width: "160" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.rawMaterial.packing) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("Warehouse.Actions"),
              fixed: "right",
              "min-width": "100px",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "router-link",
                      {
                        staticClass: "el-button el-button--default",
                        class: { "el-button--mini": _vm.device === "mobile" },
                        attrs: {
                          to:
                            "/warehouses/" +
                            _vm.$route.params.id +
                            "/areas/" +
                            (_vm.$route.params.area_id
                              ? _vm.$route.params.area_id
                              : scope.row.area.uuid) +
                            "/stock-in?rawMaterialId=" +
                            scope.row.rawMaterial.uuid,
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("WarehouseAreaRawMaterial.StockIn"))
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "router-link",
                      {
                        staticClass: "el-button el-button--default",
                        class: {
                          "el-button--mini": _vm.device === "mobile",
                          "is-disabled": scope.row.qty <= 0,
                        },
                        attrs: {
                          to:
                            scope.row.qty <= 0
                              ? "./raw-materials"
                              : "/warehouses/" +
                                _vm.$route.params.id +
                                "/areas/" +
                                (_vm.$route.params.area_id
                                  ? _vm.$route.params.area_id
                                  : scope.row.area.uuid) +
                                "/stock-out?rawMaterialId=" +
                                scope.row.rawMaterial.uuid,
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("WarehouseAreaRawMaterial.StockOut"))
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "router-link",
                      {
                        staticClass: "el-button el-button--default",
                        class: {
                          "el-button--mini": _vm.device === "mobile",
                          "is-disabled": scope.row.qty <= 0,
                        },
                        attrs: {
                          to:
                            scope.row.qty <= 0
                              ? "./raw-materials"
                              : "/warehouses/" +
                                _vm.$route.params.id +
                                "/areas/" +
                                (_vm.$route.params.area_id
                                  ? _vm.$route.params.area_id
                                  : scope.row.area.uuid) +
                                "/stock-transit?rawMaterialId=" +
                                scope.row.rawMaterial.uuid,
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t("WarehouseAreaRawMaterial.StockTransit")
                          )
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "router-link",
                      {
                        staticClass: "el-button el-button--default",
                        class: { "el-button--mini": _vm.device === "mobile" },
                        attrs: {
                          to:
                            "/warehouses/" +
                            _vm.$route.params.id +
                            "/areas/" +
                            (_vm.$route.params.area_id
                              ? _vm.$route.params.area_id
                              : scope.row.area.uuid) +
                            "/stock-take?rawMaterialId=" +
                            scope.row.rawMaterial.uuid +
                            "&qty=" +
                            scope.row.qty,
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("WarehouseAreaRawMaterial.StockTake"))
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "router-link",
                      {
                        staticClass: "el-button el-button--info",
                        class: { "el-button--mini": _vm.device === "mobile" },
                        attrs: {
                          to:
                            "/warehouses/" +
                            _vm.$route.params.id +
                            "/areas/" +
                            (_vm.$route.params.area_id
                              ? _vm.$route.params.area_id
                              : scope.row.area.uuid) +
                            "/records?rawMaterialId=" +
                            scope.row.rawMaterial.uuid,
                        },
                      },
                      [
                        _c("i", { staticClass: "el-icon-time" }),
                        _vm._v(
                          _vm._s(_vm.$t("WarehouseAreaRawMaterial.History"))
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary is-plain",
                          size: _vm.device === "mobile" ? "mini" : "default",
                          icon: "el-icon-edit",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.printPacking(scope.row.rawMaterial.uuid)
                          },
                        },
                      },
                      [_vm._v("Print Packing")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "danger",
                          size: _vm.device === "mobile" ? "mini" : "default",
                          icon: "el-icon-delete",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.deleteWarehouseAreaRawMaterial(
                              scope.row.uuid
                            )
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("WarehouseAreaRawMaterial.Delete"))
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.perPage,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "perPage", $event)
          },
          pagination: _vm.fetchData,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }