"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _errorParse = require("@/utils/error-parse");
var _elementUi = require("element-ui");
var _warehouseArea = require("@/api/warehouseArea");
var _warehouse = require("@/api/warehouse");
var _i18n = _interopRequireDefault(require("@/i18n"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data() {
    return {
      loading: false,
      warehouseArea: {
        id: null,
        name: null,
        warehouseId: null
      },
      warehouses: [],
      buttonText: null
    };
  },
  async created() {
    await (0, _warehouse.getWarehouses)({
      limit: 100
    }).then(warehouses => {
      this.warehouses = warehouses.data;
    });
    if (this.$route.params.area_id) {
      this.buttonText = _i18n.default.t('WarehouseArea.Edit');
      this.fetchData();
    } else {
      this.buttonText = _i18n.default.t('WarehouseArea.Add');
      if (this.$route.params.id) {
        this.warehouseArea.warehouseId = this.$route.params.id;
      }
    }
  },
  methods: {
    async fetchData() {
      this.loading = true;
      (0, _warehouseArea.getWarehouseArea)(this.$route.params.area_id).then(response => {
        this.warehouseArea = response.data;
        this.warehouseArea.warehouseId = this.warehouseArea.warehouse.uuid;
      }).catch(error => console.error(error)).finally(() => {
        this.loading = false;
      });
    },
    onSubmit() {
      this.$refs.form.validate(valid => {
        if (!valid) {
          return false;
        }
        this.loading = true;
        if (this.$route.params.area_id) {
          (0, _warehouseArea.editWarehouseArea)(this.warehouseArea).then(response => {
            (0, _elementUi.Message)({
              message: _i18n.default.t('WarehouseArea.WarehouseAreaupdated'),
              type: 'success',
              duration: 5 * 1000
            });
            this.$router.push(`/warehouses/` + response.data.warehouse.uuid + '/areas');
          }).catch(err => {
            (0, _elementUi.Message)({
              dangerouslyUseHTMLString: true,
              message: (0, _errorParse.parseError)(err) || _i18n.default.t('WarehouseArea.UpdateFailedMessage'),
              type: 'error',
              duration: 5 * 1000
            });
            // console.log(err)
            this.loading = false;
          });
        } else {
          (0, _warehouseArea.createWarehouseArea)(this.warehouseArea).then(response => {
            // console.log(response.data.warehouse)
            (0, _elementUi.Message)({
              message: _i18n.default.t('WarehouseArea.WarehouseAreacreated'),
              type: 'success',
              duration: 5 * 1000
            });
            this.$router.push(`/warehouses/` + response.data.warehouse.uuid + '/areas');
          }).catch(err => {
            (0, _elementUi.Message)({
              dangerouslyUseHTMLString: true,
              message: (0, _errorParse.parseError)(err) || _i18n.default.t('WarehouseArea.UpdateFailedMessage'),
              type: 'error',
              duration: 5 * 1000
            });
            this.loading = false;
          });
        }
      });
    },
    onCancel() {
      this.$router.go(-1);
    }
  }
};
exports.default = _default;