"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _pluralize = _interopRequireDefault(require("pluralize"));
var _warehouseAreaRawMaterial = require("@/api/warehouseAreaRawMaterial");
var _elementUi = require("element-ui");
var _i18n = _interopRequireDefault(require("@/i18n"));
var _errorParse = require("@/utils/error-parse");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import { getWarehouseArea } from '@/api/warehouseArea'
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  /* filters: {
    statusFilter(status) {
      const statusMap = {
        published: 'success',
        draft: 'gray',
        deleted: 'danger'
      }
      return statusMap[status]
    }
  }, */
  data() {
    return {
      list: null,
      listLoading: false,
      /* warehouseArea: {
        warehouse: null
      }, */
      listQuery: {
        page: 1,
        perPage: 10,
        listAll: true,
        keyword: null
      },
      total: 0
    };
  },
  computed: {
    device() {
      return this.$store.state.app.device;
    }
  },
  async created() {
    this.fetchData();
  },
  methods: {
    pluralize(unit) {
      if (['kg', 'gr', 'l', 'ml', 'mg'].indexOf(unit) === -1) {
        return (0, _pluralize.default)(unit);
      } else {
        return unit;
      }
    },
    async fetchData() {
      this.listLoading = true;
      (0, _warehouseAreaRawMaterial.getWarehouseAreaRawMaterials)({
        ...this.listQuery,
        warehouseAreaId: this.$route.params.area_id
      }).then(response => {
        this.list = Object.freeze(response.data.map((data, index) => {
          data.id = index + response.meta.from;
          return data;
        }));
        this.total = response.meta.total;
      }).finally(() => {
        this.listLoading = false;
      });
      /* getWarehouseArea(this.$route.params.area_id).then(warehouseArea => {
        this.warehouseArea = warehouseArea.data
      }) */
    },

    back() {
      /* if (!this.warehouseArea.warehouse) {
        this.$router.go(-1)
      } else  */
      if (this.$route.params.id) {
        this.$router.push(`/warehouses/${this.$route.params.id}/areas`);
      } else {
        this.$router.push(`/warehouses`);
      }
    },
    deleteWarehouseAreaRawMaterial(id) {
      return _elementUi.MessageBox.confirm(_i18n.default.t('WarehouseAreaRawMaterial.ConfirmDeleteMessage'), _i18n.default.t('WarehouseAreaRawMaterial.Confirm'), {
        confirmButtonText: _i18n.default.t('WarehouseAreaRawMaterial.Delete'),
        cancelButtonText: _i18n.default.t('WarehouseAreaRawMaterial.Cancel'),
        type: 'warning'
      }).then(() => (0, _warehouseAreaRawMaterial.deleteWarehouseAreaRawMaterial)(id).catch(err => err.status === 404 ? Promise.resolve('already deleted') : (0, _elementUi.Message)({
        dangerouslyUseHTMLString: true,
        message: (0, _errorParse.parseError)(err) || 'Delete failed, please retry.',
        type: 'error',
        duration: 5 * 1000
      })).then(() => {
        (0, _elementUi.Message)({
          message: _i18n.default.t('WarehouseAreaRawMaterial.Recorddeleted'),
          type: 'success',
          duration: 5 * 1000
        });
        return this.fetchData();
      })).catch(err => err === 'cancel' ? Promise.resolve('cancelled') : Promise.reject(err));
    },
    printPacking(id) {
      var ordercode = prompt('如需JP OrderCode 請輸入', '');
      const routeData = this.$router.resolve({
        name: 'Print Packing',
        path: '/raw-materials/print-packing/' + id,
        query: {
          ordercode: ordercode
        }
      });
      window.open(routeData.href, '_blank');
      // this.$router.go(`/raw-materials/print-packing/${id}?ordercode=${ordercode}`)
    }
  }
};
exports.default = _default;